import React, { createContext, useState, useContext } from 'react';

const PresentationContext = createContext();

export const usePresentation = () => useContext(PresentationContext);

export const PresentationProvider = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [quiz, setQuiz] = useState(null);
  const [poll, setPoll] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);

  return (
    <PresentationContext.Provider value={{
      currentSlide, setCurrentSlide,
      quiz, setQuiz,
      poll, setPoll,
      leaderboard, setLeaderboard
    }}>
      {children}
    </PresentationContext.Provider>
  );
};