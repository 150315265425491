// Menu.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled, { createGlobalStyle } from 'styled-components';
import { ChevronRight, Book, Cpu, ArrowLeft, Lock } from 'lucide-react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Content = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  overflow-y: auto;

  @media (min-width: 768px) {
    max-width: 80%;
  }

  @media (min-width: 1200px) {
    max-width: 70%;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: auto;
  margin: 0 auto 2rem;
  display: block;

  @media (min-width: 768px) {
    width: 150px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const Button = styled(motion.button)`
  width: 100%;
  padding: 1.2rem;
  border: none;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
    padding: 1.5rem;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const WeekSelector = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1.5rem;
  }
`;

const WeekButton = styled(motion.button)`
  aspect-ratio: 1 / 1;
  border: none;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }
`;

const BackButton = styled(motion.button)`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 1.2rem;
  border: none;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
    padding: 1.5rem;
  }
`;

const Menu = ({ history }) => {
  const [view, setView] = useState('main');
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [adminPassword, setAdminPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [disabledWeeks, setDisabledWeeks] = useState([2, 3, 4, 5, 6]); // Example: weeks 2 and 4 are disabled

  useEffect(() => {
    const userId = Cookies.get('userId') || Math.random().toString(36).substr(2, 9);
    Cookies.set('userId', userId, { expires: 7 });
    const storedUserName = Cookies.get('userName');
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  const handleTeAraMatihikoWeekSelect = (week) => {
    setSelectedWeek(week);
    setView('courseDetails');
  };

  const handleStartCourse = () => {
    if (!userName) {
      alert('Please enter your name before starting the course.');
      return;
    }
    Cookies.set('userName', userName, { expires: 7 });
    if (selectedWeek) {
      history.push(`/te-ara-matihiko/${selectedWeek}`);
    }
  };

  const handleAdminLogin = () => {
    if (adminPassword === "deadpool") {
      history.push('/admin');
    } else {
      alert('Invalid admin password');
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    },
    exit: { opacity: 0 }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Content
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <AnimatePresence mode="wait">
            {view === 'main' && (
              <motion.div key="main" variants={containerVariants} initial="hidden" animate="visible" exit="exit">
                <Logo src="./pursuit.png" alt="Logo" />
                <ButtonGroup>
                  <Button
                    variants={itemVariants}
                    onClick={() => setView('teAraMatihikoWeekSelect')}
                    disabled
                  >
                    <span>Te Ara Matihiko</span>
                    <ChevronRight />
                  </Button>
                  <Button
                    variants={itemVariants}
                    onClick={() => history.push('/ai-workshop')}
                  
                  >
                    <span>AI Workshop</span>
                    <Cpu />
                  </Button>
                </ButtonGroup>
              </motion.div>
            )}

            {view === 'teAraMatihikoWeekSelect' && (
              <motion.div key="teAraMatihikoWeekSelect" variants={containerVariants} initial="hidden" animate="visible" exit="exit">
                <BackButton onClick={() => setView('main')} variants={itemVariants}>
                  <ArrowLeft />
                  Back
                </BackButton>
                <WeekSelector variants={containerVariants}>
                  {Array.from({ length: 5 }, (_, i) => (
                    <WeekButton
                      key={i}
                      onClick={() => handleTeAraMatihikoWeekSelect(i + 1)}
                      variants={itemVariants}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      disabled={disabledWeeks.includes(i + 1)}
                    >
                      Week {i + 1}
                    </WeekButton>
                  ))}
                </WeekSelector>
              </motion.div>
            )}

            {view === 'courseDetails' && (
              <motion.div key="courseDetails" variants={containerVariants} initial="hidden" animate="visible" exit="exit">
                <BackButton onClick={() => setView('teAraMatihikoWeekSelect')} variants={itemVariants}>
                  <ArrowLeft />
                  Back
                </BackButton>
                <Input
                  type="text"
                  placeholder="Enter your name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <Button variants={itemVariants} onClick={handleStartCourse}>
                  <span>Start</span>
                  <Book />
                </Button>
              </motion.div>
            )}

            {view === 'adminLogin' && (
              <motion.div key="adminLogin" variants={containerVariants} initial="hidden" animate="visible" exit="exit">
                <BackButton onClick={() => setView('main')} variants={itemVariants}>
                  <ArrowLeft />
                  Back
                </BackButton>
                <Input
                  type="password"
                  placeholder="Digital Facilitator"
                  value={adminPassword}
                  onChange={(e) => setAdminPassword(e.target.value)}
                />
                <Button variants={itemVariants} onClick={handleAdminLogin}>
                  <span>Login</span>
                  <Lock />
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </Content>
      </Container>
    </>
  );
};

export default withRouter(Menu);
