import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect, useCallback } from 'react';

const quotes = [
  {
    text: "Artificial intelligence is the science and engineering of making intelligent machines, especially intelligent computer programs.",
    author: "John McCarthy",
  },
  {
    text: "A computer would deserve to be called intelligent if it could deceive a human into believing that it was human.",
    author: "Alan Turing",
  },
];

const QuotePresentation = () => {
  const [currentQuote, setCurrentQuote] = useState(0);
  const [fontSize, setFontSize] = useState('4vw');

  const updateFontSize = useCallback(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const aspectRatio = vw / vh;
    if (aspectRatio < 1) {
      setFontSize('6vw');
    } else if (aspectRatio < 1.5) {
      setFontSize('4vw');
    } else {
      setFontSize('3vw');
    }
  }, []);

  useEffect(() => {
    updateFontSize();
    window.addEventListener('resize', updateFontSize);
    return () => window.removeEventListener('resize', updateFontSize);
  }, [updateFontSize]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  const containerStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    background: 'linear-gradient(to bottom, #000428, #004e92)',
    overflow: 'hidden',
    padding: '0 5%',
  };

  const textStyle = {
    color: 'white',
    fontSize: fontSize,
    fontWeight: '400',
    fontFamily: "'Merriweather', serif",
    textAlign: 'center',
    lineHeight: '1.5',
    textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
  };

  const authorStyle = {
    fontWeight: 'bold',
    marginTop: '1rem',
    opacity: 0.8,
  };

  return (
    <div style={containerStyle}>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentQuote}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{ duration: 1.5 }}
          style={textStyle}
        >
          "{quotes[currentQuote].text}"
          <div style={authorStyle}>- {quotes[currentQuote].author}</div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default QuotePresentation;
