import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const imageRef = useRef(null);

  const images = [
    { src: "https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_fd6d2dbf-851a-4757-bae0-d43313d64063.png", heading: "Version 1" },
    { src: "https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_b23eff56-e98b-45c9-b3d2-8eb50dd8946f.png", heading: "Version 2" },
    { src: "https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_c69dfe40-5187-4386-9a82-b3aba0b16df0.png", heading: "Version 3" },
    { src: "https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_1b4a5b8c-f688-443f-87f6-f5d55a0b617f.png", heading: "Version 4" },
    { src: "https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_c32aa607-07d4-4fca-951f-aba0277ee88b.png", heading: "Version 5" },
    { src: "https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_97f1fb96-5290-44a7-b669-4b0fed9e7e80.png", heading: "Version 6" }
  ];

  const nextImage = () => setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  const prevImage = () => setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);

  const handleSwipe = (direction) => {
    if (direction === 'left') nextImage();
    if (direction === 'right') prevImage();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') nextImage();
    if (e.key === 'ArrowLeft') prevImage();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    };

    const loadAllImages = async () => {
      try {
        await Promise.all(images.map(image => loadImage(image.src)));
        setImagesLoaded(true);
      } catch (error) {
        console.error('Failed to load images:', error);
        setImagesLoaded(true); // Set to true even on error to allow rendering
      }
    };

    loadAllImages();
  }, []);

  if (!imagesLoaded) {
    return <LoadingContainer>Loading images...</LoadingContainer>;
  }

  return (
    <SliderContainer>
      <AnimatePresence initial={false}>
        <ImageContainer
          key={currentIndex}
          ref={imageRef}
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ duration: 0.5 }}
          onPanEnd={(_, info) => handleSwipe(info.offset.x > 0 ? 'right' : 'left')}
        >
          <Image src={images[currentIndex].src} alt={`Slide ${currentIndex + 1}`} />
          <ImageHeading>{images[currentIndex].heading}</ImageHeading>
        </ImageContainer>
      </AnimatePresence>
      <NavButton left onClick={prevImage}>&#10094;</NavButton>
      <NavButton right onClick={nextImage}>&#10095;</NavButton>
      <ProgressDots>
        {images.map((_, index) => (
          <Dot key={index} active={index === currentIndex} onClick={() => setCurrentIndex(index)} />
        ))}
      </ProgressDots>
    </SliderContainer>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #fff;
  background: #111;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111;
  overflow: hidden;
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ImageHeading = styled.h2`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  margin: 0;
  text-align: center;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  padding: 15px 20px;
  font-size: 24px;
  cursor: pointer;
  transition: background 0.3s;
  ${({ left }) => left && 'left: 20px;'}
  ${({ right }) => right && 'right: 20px;'}

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const ProgressDots = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ active }) => (active ? '#fff' : 'rgba(255, 255, 255, 0.5)')};
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
`;

export default ImageSlider;