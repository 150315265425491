import React from 'react';

const DreamMachine = () => {
  const url = "https://lumalabs.ai/dream-machine";

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw'
    }}>
      <button
        onClick={handleClick}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        Open Dream Machine
      </button>
    </div>
  );
};

export default DreamMachine;