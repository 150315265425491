import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import * as fal from "@fal-ai/serverless-client";
import { FaDownload, FaExpand, FaCompress, FaQrcode } from 'react-icons/fa';
import QRCode from 'qrcode.react';

fal.config({
  credentials: process.env.REACT_APP_FAL_KEY
});

const GeneralPromptGeneration = () => {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [error, setError] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [qrCodeVisible, setQrCodeVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e) => {
    setPrompt(e.target.value);
    setError(null);
  };

  const handleGenerate = async () => {
    if (!prompt) {
      setError('Please enter a prompt.');
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const result = await fal.subscribe("fal-ai/flux-realism", {
        input: {
          prompt: prompt,
          image_size: "landscape_16_9",
          num_inference_steps: 50,
          guidance_scale: 3.5,
          num_images: 1,
          enable_safety_checker: true
        },
      });

      if (result.images && result.images.length > 0) {
        setGeneratedImage(result.images[0].url);
      } else {
        throw new Error('No image generated');
      }
    } catch (err) {
      setError('Failed to generate image. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setGeneratedImage(null);
    setPrompt('');
    setImageLoaded(false);
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = generatedImage;
    link.download = 'generated_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const toggleQrCode = () => {
    setQrCodeVisible(!qrCodeVisible);
  };

  return (
    <Container style={{ height: `${windowHeight}px`, width: `${windowWidth}px` }}>
      <ContentWrapper>
        <AnimatePresence mode="wait">
          {!isLoading && !generatedImage ? (
            <InputSection
              key="input"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <Header>Image Generator via Flux</Header>
              {error && <Error>{error}</Error>}
              <Form>
                <InputField
                  type="text"
                  value={prompt}
                  onChange={handleChange}
                  placeholder="Enter your prompt"
                  onKeyPress={(e) => e.key === 'Enter' && handleGenerate()}
                />
                <GenerateButton onClick={handleGenerate}>Generate Image</GenerateButton>
              </Form>
            </InputSection>
          ) : isLoading ? (
            <LoadingSection
              key="loading"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.5 }}
            >
              <Spinner />
            </LoadingSection>
          ) : (
            <ResultSection
              key="result"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <ImageWrapper>
                <GeneratedImage
                  src={generatedImage}
                  alt="Generated Image"
                  onLoad={() => setImageLoaded(true)}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={imageLoaded ? { opacity: 1, scale: 1 } : {}}
                  transition={{ duration: 0.5 }}
                />
                {!imageLoaded && <ImagePlaceholder />}
              </ImageWrapper>
              <ImageControls>
                <ControlButton onClick={downloadImage}>
                  <FaDownload />
                </ControlButton>
                <ControlButton onClick={toggleQrCode}>
                  <FaQrcode />
                </ControlButton>
                <ControlButton onClick={toggleFullscreen}>
                  {isFullscreen ? <FaCompress /> : <FaExpand />}
                </ControlButton>
              </ImageControls>
              <AnimatePresence>
                {qrCodeVisible && (
                  <QrCodeWrapper
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                  >
                    <QRCode value={generatedImage} />
                  </QrCodeWrapper>
                )}
              </AnimatePresence>
            </ResultSection>
          )}
        </AnimatePresence>
      </ContentWrapper>
      {generatedImage && (
        <FixedButton
          onClick={resetForm}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Generate Another Image
        </FixedButton>
      )}
      <AnimatePresence>
        {isFullscreen && (
          <FullscreenOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            onClick={toggleFullscreen}
          >
            <FullscreenImage src={generatedImage} alt="Fullscreen Generated Image" />
          </FullscreenOverlay>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #3498db, #8e44ad);
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

const Header = styled.h1`
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Error = styled(motion.div)`
  color: #e74c3c;
  margin-bottom: 1rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: clamp(0.8rem, 2vw, 1rem);
  animation: shake 0.5s;
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(10px); }
    50% { transform: translateX(-10px); }
    75% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }
`;

const InputSection = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputField = styled(motion.input)`
  width: 100%;
  padding: 0.75rem;
  font-size: clamp(0.8rem, 2vw, 1rem);
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-weight: bold;
  &:focus {
    outline: none;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`;

const Button = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  font-size: clamp(0.8rem, 2vw, 1rem);
  color: #3498db;
  background-color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  &:hover {
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`;

const GenerateButton = styled(Button)`
  width: 100%;
`;

const LoadingSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Spinner = styled(motion.div)`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ResultSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: 500px;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

const GeneratedImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImagePlaceholder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  
  &::after {
    content: 'Loading...';
    color: white;
    font-size: clamp(1rem, 3vw, 1.5rem);
  }
`;

const ImageControls = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const ControlButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  box-shadow: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const QrCodeWrapper = styled(motion.div)`
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FixedButton = styled(Button)`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
`;

const FullscreenOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FullscreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

export default GeneralPromptGeneration;