import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaExternalLinkAlt } from 'react-icons/fa';

const OPENAI_API_KEY = process.env.REACT_APP_LLM_API_KEY;

const examples = [
  "Roses are red, violets are…",
  "The quick brown fox jumps over the…",
  "Rome wasn’t built in a…",
];

const Completion = () => {
  const [input, setInput] = useState('');
  const [completion, setCompletion] = useState('');
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    setCompletion('');
  };

  const handleComplete = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [
            { role: 'system', content: 'You are a helpful assistant. Complete only the necessary next word or phrase without additional information.' },
            { role: 'user', content: input },
          ],
          max_tokens: 10,
          temperature: 0.5,
        }),
      });
      const data = await response.json();
      setCompletion(data.choices[0].message.content.trim());
    } catch (error) {
      setCompletion('An error occurred while fetching the completion.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {showImage ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            width: '100vw',
            background: 'linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d)',
            padding: '2rem',
            boxSizing: 'border-box',
          }}
        >
          <motion.img
            src="/chatgpttimeline.png"
            alt="Flash Image"
            style={{
              width: '90%',
              height: 'auto',
              maxWidth: '1000px',
              maxHeight: '90vh',
              padding: '1rem',
            }}
          />
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            width: '100vw',
            background: 'linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d)',
            padding: '2rem',
            fontFamily: 'Arial, sans-serif',
            color: 'white',
            boxSizing: 'border-box',
          }}
        >
          <motion.div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                fontFamily: 'Verdana, sans-serif',
                fontWeight: 'bold',
                fontSize: '2.2rem',
                textAlign: 'center',
                marginRight: '1rem',
              }}
            >
              What Actually Is ChatGPT?
            </motion.h1>
            <motion.a
              href="https://chat.openai.com/"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{ color: 'white', fontSize: '1.5rem' }}
            >
              <FaExternalLinkAlt />
            </motion.a>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            style={{
              width: '100%',
              maxWidth: '400px',
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              padding: '1.5rem',
              borderRadius: '10px',
              boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
            }}
          >
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              placeholder="Enter text to complete..."
              style={{
                width: '100%',
                padding: '0.85rem',
                marginBottom: '1rem',
                borderRadius: '6px',
                border: '1px solid #555',
                backgroundColor: '#2c2c2c',
                color: 'white',
                fontSize: '1rem',
                boxSizing: 'border-box',
              }}
            />
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleComplete}
              disabled={loading || !input}
              style={{
                width: '100%',
                padding: '0.85rem',
                backgroundColor: loading || !input ? '#555' : '#fdbb2d',
                color: 'white',
                border: 'none',
                borderRadius: '6px',
                cursor: loading || !input ? 'not-allowed' : 'pointer',
                fontSize: '1rem',
                fontWeight: 'bold',
                boxSizing: 'border-box',
              }}
            >
              {loading ? 'Completing...' : 'Complete'}
            </motion.button>
            {completion && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  marginTop: '1.2rem',
                  padding: '1rem',
                  backgroundColor: '#444',
                  borderRadius: '6px',
                }}
              >
                <p style={{ fontWeight: 'bold', color: '#fdbb2d', marginBottom: '0.5rem' }}>Completion:</p>
                <p style={{ margin: 0 }}>{completion}</p>
              </motion.div>
            )}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p style={{ fontWeight: 'bold', color: '#fdbb2d', marginBottom: '1rem' }}>Examples:</p>
            {examples.map((example, index) => (
              <motion.button
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setInput(example)}
                style={{
                  marginBottom: '0.75rem',
                  padding: '0.85rem',
                  width: '100%',
                  maxWidth: '300px',
                  backgroundColor: '#fdbb2d',
                  color: 'black',
                  borderRadius: '6px',
                  border: 'none',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
              >
                {example}
              </motion.button>
            ))}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Completion;
