import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const images = [
  '/einsteinreal.jpg',
  '/Einstein.png',
  '/Einstein2.png',
  '/Einstein.png',
];

const GPTEinstein = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const nextImage = useCallback(() => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, []);

  const prevImage = useCallback(() => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  }, []);

  useEffect(() => {
    const timer = setInterval(nextImage, 15000);
    return () => clearInterval(timer);
  }, [nextImage]);

  const handleNext = () => {
    if (!isTransitioning) {
      nextImage();
    }
  };

  const handlePrev = () => {
    if (!isTransitioning) {
      prevImage();
    }
  };

  const handleDotClick = (index) => {
    if (!isTransitioning && index !== currentIndex) {
      setIsTransitioning(true);
      setCurrentIndex(index);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100vw',
        background: 'linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d)',
        position: 'relative',
        padding: '2rem',
        boxSizing: 'border-box',
      }}
    >
      <AnimatePresence mode="wait" onExitComplete={() => setIsTransitioning(false)}>
        <motion.img
          key={currentIndex}
          src={images[currentIndex]}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.8, ease: 'easeInOut' }}
          style={{
            width: '90%',
            height: 'auto',
            maxWidth: '1000px',
            maxHeight: '90vh',
            borderRadius: '10px',
          }}
        />
      </AnimatePresence>

      {currentIndex === 0 && (
        <div
          style={{
            marginTop: '1rem',
            color: '#fff',
            textAlign: 'center',
            fontSize: '1.2rem',
            maxWidth: '90%',
            background: 'rgba(0, 0, 0, 0.6)',
            padding: '1rem',
            borderRadius: '10px',
          }}
        >
        Albert Einstein was a German scientist who loved to think about how the world works. 
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => handleDotClick(index)}
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              margin: '0 5px',
              backgroundColor: currentIndex === index ? '#fff' : '#888',
              border: 'none',
              cursor: 'pointer',
            }}
          />
        ))}
      </div>

      <button
        onClick={handlePrev}
        style={{
          position: 'absolute',
          left: '2%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          fontSize: '1.5rem',
          cursor: 'pointer',
        }}
      >
        &#10094;
      </button>

      <button
        onClick={handleNext}
        style={{
          position: 'absolute',
          right: '2%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          fontSize: '1.5rem',
          cursor: 'pointer',
        }}
      >
        &#10095;
      </button>
    </div>
  );
};

export default GPTEinstein;
