import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import YouTube from 'react-youtube';

const videoData = [
  {
    title: "Automation",
    videoId: "0SRVJaOg9Co",
  },
  {
    title: "HEALTHCARE",
    videoId: "D3oRN5JNMWs",
  },
  {
    title: "TECHNICAL SUPPORT",
    videoId: "vg97bkBk19c",
  },
];

const GPTEinstein = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [fontSize, setFontSize] = useState('6vw');
  const [videoSize, setVideoSize] = useState({ width: '100%', height: '100%' });

  const handleVideoEnd = useCallback(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      setShowVideo(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % videoData.length);
      setIsTransitioning(false);
    }, 5000);
  }, []);

  const updateSizes = useCallback(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const aspectRatio = vw / vh;

    if (aspectRatio < 1) {
      setFontSize('8vw');
      setVideoSize({ width: '100%', height: `${(vw / 16) * 9}px` });
    } else if (aspectRatio < 1.5) {
      setFontSize('6vw');
      setVideoSize({ width: '100%', height: '100%' });
    } else {
      setFontSize('4vw');
      setVideoSize({ width: `${(vh / 9) * 16}px`, height: '100%' });
    }
  }, []);

  useEffect(() => {
    updateSizes();
    window.addEventListener('resize', updateSizes);
    return () => window.removeEventListener('resize', updateSizes);
  }, [updateSizes]);

  useEffect(() => {
    if (showIntro) {
      const introTimeout = setTimeout(() => {
        setShowIntro(false);
      }, 3000);
      return () => clearTimeout(introTimeout);
    } else {
      const videoTimeout = setTimeout(() => {
        setShowVideo(true);
      }, 3000);
      return () => clearTimeout(videoTimeout);
    }
  }, [currentIndex, showIntro]);

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 0,
      modestbranding: 1,
      fs: 1,
      playsinline: 1,
    },
  };

  const containerStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    background: 'black',
    overflow: 'hidden',
  };

  const textStyle = {
    position: 'absolute',
    color: 'white',
    fontSize: fontSize,
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    whiteSpace: 'normal',
    maxWidth: '90%',
    wordWrap: 'break-word',
  };

  const videoContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: videoSize.width,
    height: videoSize.height,
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'hidden',
  };

  return (
    <div style={containerStyle}>
      <AnimatePresence mode="wait">
        {showIntro && (
          <motion.div
            key="intro"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.2 }}
            transition={{ duration: 1.5 }}
            style={textStyle}
          >
            AI IN VARIOUS INDUSTRIES
          </motion.div>
        )}
        {!showIntro && !showVideo && !isTransitioning && (
          <motion.div
            key={`title-${currentIndex}`}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 1.5 }}
            style={textStyle}
          >
            {videoData[currentIndex].title}
          </motion.div>
        )}
        {showVideo && (
          <motion.div
            key={`video-${currentIndex}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.5 }}
            style={videoContainerStyle}
          >
            <YouTube
              videoId={videoData[currentIndex].videoId}
              opts={opts}
              onEnd={handleVideoEnd}
              style={{ width: '100%', height: '100%' }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GPTEinstein;