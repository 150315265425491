import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import * as fal from "@fal-ai/serverless-client";
import { FaDownload, FaExpand, FaCompress, FaQrcode, FaTrash, FaExternalLinkAlt } from 'react-icons/fa';
import QRCode from 'qrcode.react';

fal.config({
  credentials: process.env.REACT_APP_FAL_KEY
});

const OptimizedExpandedImageGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [imageHistory, setImageHistory] = useState([]);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [qrCodeVisible, setQrCodeVisible] = useState(false);

  const handleChange = (e) => {
    setPrompt(e.target.value);
    setError(null);
  };

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt.');
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const result = await fal.subscribe("fal-ai/flux-realism", {
        input: {
          prompt: prompt.trim(),
          image_size: "landscape_16_9",
          num_inference_steps: 50,
          guidance_scale: 3.5,
          num_images: 1,
          enable_safety_checker: true
        },
      });

      if (result.images && result.images.length > 0) {
        const newImage = { url: result.images[0].url, prompt: prompt.trim() };
        setGeneratedImage(newImage);
        setImageHistory(prevHistory => [newImage, ...prevHistory]);
      } else {
        throw new Error('No image generated');
      }
    } catch (err) {
      setError('Failed to generate image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadImage = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'generated_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);
  const toggleQrCode = () => setQrCodeVisible(!qrCodeVisible);
  const removeFromHistory = (index) => {
    setImageHistory(prevHistory => prevHistory.filter((_, i) => i !== index));
  };

  return (
    <Container>
      <ResponsiveWrapper>
        <Header>
         Generate Images
        
        </Header>
      
        <InputSection>
          <StyledTextArea
            value={prompt}
            onChange={handleChange}
            placeholder="Describe what you wish to see. It's good at creating animals, objects, but this one specifically is not great for logo design."
            rows={4}
          />
          <ResponsiveButton
            onClick={handleGenerate}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading}
          >
            {isLoading ? 'Generating...' : 'Generate Image'}
          </ResponsiveButton>
        </InputSection>
        {error && <Error initial={{ opacity: 0 }} animate={{ opacity: 1 }}>{error}</Error>}
        <AnimatePresence mode="wait">
          {isLoading ? (
            <LoadingSection
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Spinner />
            </LoadingSection>
          ) : generatedImage && (
            <ResultSection
              key="result"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <ResponsiveImageWrapper>
                <GeneratedImage
                  src={generatedImage.url}
                  alt="Generated Image"
                  onLoad={() => setImageLoaded(true)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: imageLoaded ? 1 : 0 }}
                />
                {!imageLoaded && <ImagePlaceholder />}
              </ResponsiveImageWrapper>
              <ImageControls>
                <ControlButton onClick={() => downloadImage(generatedImage.url)}>
                  <FaDownload />
                </ControlButton>
                <ControlButton onClick={toggleQrCode}>
                  <FaQrcode />
                </ControlButton>
                <ControlButton onClick={toggleFullscreen}>
                  {isFullscreen ? <FaCompress /> : <FaExpand />}
                </ControlButton>
              </ImageControls>
              <AnimatePresence>
                {qrCodeVisible && (
                  <QrCodeWrapper
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                  >
                    <QRCode value={generatedImage.url} size={128} />
                  </QrCodeWrapper>
                )}
              </AnimatePresence>
            </ResultSection>
          )}
        </AnimatePresence>
        <HistorySection>
          <HistoryHeader>History</HistoryHeader>
          <ResponsiveHistoryGrid>
            {imageHistory.map((image, index) => (
              <HistoryItem key={index}>
                <ResponsiveHistoryImage src={image.url} alt={`Generated Image ${index + 1}`} />
                <HistoryPrompt>{image.prompt}</HistoryPrompt>
                <HistoryControls>
                  <HistoryButton onClick={() => downloadImage(image.url)}>
                    <FaDownload />
                  </HistoryButton>
                  <HistoryButton onClick={() => removeFromHistory(index)}>
                    <FaTrash />
                  </HistoryButton>
                </HistoryControls>
              </HistoryItem>
            ))}
          </ResponsiveHistoryGrid>
        </HistorySection>
      </ResponsiveWrapper>
      <AnimatePresence>
        {isFullscreen && (
          <FullscreenOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={toggleFullscreen}
          >
            <FullscreenImage src={generatedImage.url} alt="Fullscreen Generated Image" />
          </FullscreenOverlay>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default OptimizedExpandedImageGenerator;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  padding: 2rem 1rem;
  box-sizing: border-box;
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  padding: 2rem;
  box-sizing: border-box;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }

  @media (max-width: 480px) {
    padding: 1rem 0.5rem;
  }
`;

const Header = styled.h1`
  font-size: clamp(2rem, 5vw, 3.5rem);
  color: white;
  margin-bottom: 2rem;
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LinkIcon = styled.a`
  font-size: 1rem;
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: #fdbb2d;
  }
`;

const InputSection = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: center;

  @media (max-width: 480px) {
    max-width: 90%;
    width: 90%;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 1.2rem;
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  border: none;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.95);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  resize: vertical;
  min-height: 120px;
  font-family: 'Poppins', sans-serif;

  &:focus {
    outline: none;
    background-color: white;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 480px) {
    padding: 1rem;
    font-size: clamp(0.8rem, 3vw, 1rem);
    min-height: 100px;
  }
`;

const Button = styled(motion.button)`
  padding: 0.8rem 1.6rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  color: white;
  background-color: #4a90e2;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: #357abd;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (max-width: 480px) {
    padding: 0.6rem 1.2rem;
    font-size: clamp(0.8rem, 3vw, 0.9rem);
  }
`;

const ResponsiveButton = styled(Button)`
  align-self: center;
  min-width: 220px;

  @media (max-width: 480px) {
    min-width: 160px;
  }
`;

const Error = styled(motion.div)`
  color: #ff6b6b;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;

  @media (max-width: 480px) {
    padding: 0.8rem 1rem;
    font-size: clamp(0.8rem, 3vw, 1rem);
  }
`;

const LoadingSection = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  @media (max-width: 480px) {
    height: 200px;
  }
`;

const Spinner = styled.div`
  width: 60px;
  height: 60px;
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ResultSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const ResponsiveImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  aspect-ratio: 16 / 9;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (max-width: 480px) {
    aspect-ratio: 4 / 3;
  }
`;

const GeneratedImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImagePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: 'Loading...';
    color: white;
    font-size: clamp(1rem, 3vw, 1.5rem);
    font-weight: 600;
  }
`;

const ImageControls = styled.div`
  display: flex;
  gap: 1.2rem;
  margin-top: 1.2rem;

  @media (max-width: 480px) {
    gap: 0.8rem;
  }
`;

const ControlButton = styled(Button)`
  padding: 0.8rem;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 50%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.35);
  }

  @media (max-width: 480px) {
    padding: 0.6rem;
  }
`;

const QrCodeWrapper = styled(motion.div)`
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
`;

const HistorySection = styled.div`
  width: 100%;
  margin-top: 2.5rem;
`;

const HistoryHeader = styled.h2`
  font-size: clamp(1.8rem, 4vw, 2.8rem);
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
`;

const ResponsiveHistoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.8rem;
  }
`;

const HistoryItem = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const ResponsiveHistoryImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 175px;
  }

  @media (max-width: 480px) {
    height: 150px;
  }
`;

const HistoryPrompt = styled.p`
  padding: 0.6rem 0.8rem;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
  color: white;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
`;

const HistoryControls = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.6rem 0;
  gap: 1rem;
`;

const HistoryButton = styled(Button)`
  padding: 0.6rem;
  font-size: clamp(0.8rem, 2.5vw, 0.9rem);
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);

  &:hover {
    background-color: rgba(255, 255, 255, 0.35);
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    font-size: clamp(0.7rem, 3vw, 0.8rem);
  }
`;

const FullscreenOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  box-sizing: border-box;
`;

const FullscreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;

  @media (max-width: 480px) {
    max-width: 100%;
    max-height: 100%;
  }
`;

