import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

const OPENAI_API_KEY = process.env.REACT_APP_LLM_API_KEY;

const AIDiscussion = () => {
  const [chatInput, setChatInput] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  const handleChatSubmit = async () => {
    if (chatInput.trim()) {
      const newChatLog = [...chatLog, { role: 'user', content: chatInput }];
      setChatLog(newChatLog);
      setIsLoading(true);

      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-4',
            messages: [{ role: 'user', content: chatInput }],
            max_tokens: 150,
          },
          {
            headers: {
              Authorization: `Bearer ${OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const botResponse = response.data.choices[0].message.content;

        setChatLog((prevLog) => [
          ...prevLog,
          { role: 'bot', content: botResponse },
        ]);

        setMessageCount((prevCount) => prevCount + 1);
      } catch (error) {
        setChatLog((prevLog) => [
          ...prevLog,
          { role: 'bot', content: 'Sorry, something went wrong.' },
        ]);
      } finally {
        setIsLoading(false);
        setChatInput('');
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        width: '100vw',
        height: '100vh',
        margin: '0',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
        background: 'linear-gradient(135deg, rgb(22, 61, 119), rgb(40, 98, 148), rgb(38, 37, 86))',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          background: 'linear-gradient(135deg, #007BFF, #00FFB5)',
          width: '100%',
          maxWidth: '800px',
          textAlign: 'center',
          padding: '1rem',
          borderRadius: '10px',
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <motion.h1
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: 'easeOut' }}
          style={{ fontSize: 'calc(2rem + 1vw)', color: '#fff' }}
        >
          AI and Mental Health
        </motion.h1>
        <motion.p
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.3, ease: 'easeOut' }}
          style={{
            color: '#fff',
            fontSize: 'calc(1rem + 0.5vw)',
            marginTop: '1rem',
          }}
        >
          {messageCount < 1
            ? 'Explore the role of AI in providing mental health support.'
            : 'This is an example of a default chatbot with no personality, instruction, or behaviour. How could this chatbot be made more engaging for mental health?'}
        </motion.p>
      </motion.div>

      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          textAlign: 'left',
          marginTop: '2rem',
          overflowY: 'auto',
          height: '40vh',
          padding: '1rem',
          backgroundColor: '#f8f9fa',
          borderRadius: '10px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          scrollbarWidth: 'thin',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
      >
        <ul style={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
          {chatLog.map((log, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: log.role === 'user' ? -100 : 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
              style={{
                marginBottom: '1rem',
                fontSize: 'calc(1rem + 0.2vw)',
                backgroundColor: log.role === 'user' ? '#d4f1f9' : '#e6f7ff',
                padding: '1rem',
                borderRadius: log.role === 'user' ? '20px 20px 20px 0' : '20px 20px 0 20px',
                alignSelf: log.role === 'user' ? 'flex-start' : 'flex-end',
                maxWidth: '80%',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <strong>{log.role === 'user' ? 'You' : 'AI'}:</strong> {log.content}
            </motion.li>
          ))}
        </ul>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        style={{
          backgroundColor: '#f4f4f4',
          padding: '1.5rem',
          borderRadius: '10px',
          marginTop: '2rem',
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <input
          type="text"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          placeholder="Ask the AI chatbot..."
          style={{
            padding: '0.7rem',
            width: '100%',
            borderRadius: '5px',
            border: '1px solid #ccc',
            marginBottom: '1rem',
            fontSize: 'calc(1rem + 0.2vw)',
          }}
          disabled={isLoading}
        />
        <motion.button
          onClick={handleChatSubmit}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isLoading}
          style={{
            padding: '0.7rem 1.5rem',
            backgroundColor: isLoading ? '#ccc' : '#007BFF',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: isLoading ? 'not-allowed' : 'pointer',
            width: '100%',
            textAlign: 'center',
            fontSize: 'calc(1rem + 0.2vw)',
          }}
        >
          {isLoading ? 'Loading...' : 'Chat'}
        </motion.button>
      </motion.div>
    </div>
  );
};

export default AIDiscussion;
