import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

const VERSIONS = ['v1', 'v2', 'v3', 'v4', 'v5', 'v6'];
const EXAMPLE_PROMPTS = [
  "a woman in a blue dress",
  "a futuristic cityscape",
  "a whimsical treehouse",
  "an AI-powered robot teacher"
];

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #1a202c;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
`;

const PromptBox = styled(motion.div)`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: clamp(2rem, 5vw, 4rem);
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Subtitle = styled(motion.h2)`
  font-size: clamp(1rem, 3vw, 1.5rem);
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  width: 100%;
  max-width: 400px;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: none;
  margin-bottom: 1rem;
`;

const Button = styled(motion.button)`
  background-color: #4299e1;
  color: white;
  padding: 0.75rem 2rem;
  font-size: clamp(1rem, 2vw, 1.25rem);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3182ce;
  }
`;

const ExamplePrompts = styled(motion.div)`
  margin-top: 2rem;
  text-align: left;
`;

const ExamplePromptList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ExamplePrompt = styled(motion.li)`
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
  padding-left: 1.5em;
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: 0;
    color: #4299e1;
  }

  &:hover {
    color: #63b3ed;
  }
`;

const GenerationTimeline = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 66%;
  padding-right: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 2rem;
  }
`;

const Image = styled(motion.img)`
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

const TextContainer = styled.div`
  width: 33%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VersionText = styled(motion.h2)`
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: bold;
  margin-bottom: 1rem;
`;

const StatusText = styled(motion.p)`
  font-size: clamp(1rem, 2vw, 1.25rem);
  margin-bottom: 1rem;
`;

const PromptText = styled(motion.p)`
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  color: #a0aec0;
  margin-bottom: 1rem;
`;

const AnalysisText = styled(motion.p)`
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  color: #e2e8f0;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
`;

const ImageGenerationTimeline = () => {
  const [prompt, setPrompt] = useState('');
  const [currentVersion, setCurrentVersion] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [showPromptBox, setShowPromptBox] = useState(true);
  const [showNextButton, setShowNextButton] = useState(false);
  const [analysis, setAnalysis] = useState('');

  const generateImage = async (version) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return `https://via.placeholder.com/600x400?text=${version}`;
  };

  const simulateAnalysis = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return "The AI successfully captured the main elements of the prompt, including the subject and color. However, it struggled with some fine details and the background composition could be improved.";
  };

  const startGeneration = async () => {
    setShowPromptBox(false);
    for (const version of VERSIONS) {
      setCurrentVersion(version);
      const imageUrl = await generateImage(version);
      setGeneratedImage(imageUrl);
      const analysisResult = await simulateAnalysis();
      setAnalysis(analysisResult);
      setShowNextButton(false);
      await new Promise(resolve => setTimeout(resolve, 20000));
      setShowNextButton(true);
      if (version === VERSIONS[VERSIONS.length - 1]) break;
      await new Promise(resolve => setTimeout(resolve, 3000));
    }
  };

  const handleNext = () => {
    const currentIndex = VERSIONS.indexOf(currentVersion);
    if (currentIndex < VERSIONS.length - 1) {
      setCurrentVersion(VERSIONS[currentIndex + 1]);
      setShowNextButton(false);
    }
  };

  const handlePrevious = () => {
    const currentIndex = VERSIONS.indexOf(currentVersion);
    if (currentIndex > 0) {
      setCurrentVersion(VERSIONS[currentIndex - 1]);
      setShowNextButton(true);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: { opacity: 0 }
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <AnimatePresence mode="wait">
          {showPromptBox ? (
            <PromptBox
              key="prompt-box"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Title
                initial={{ scale: 0.8 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1.5, delay: 0.5, ease: "easeOut" }}
              >
                AI IMAGE GENERATION
              </Title>
              <Subtitle
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 1.5, delay: 1, ease: "easeOut" }}
              >
                Enter your image prompt:
              </Subtitle>
              <Input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="e.g., a woman in a blue dress"
              />
              <Button
                onClick={startGeneration}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Generate
              </Button>
              <ExamplePrompts
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.5 }}
              >
                <h3>Example prompts:</h3>
                <ExamplePromptList>
                  {EXAMPLE_PROMPTS.map((examplePrompt, index) => (
                    <ExamplePrompt
                      key={index}
                      onClick={() => setPrompt(examplePrompt)}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      {examplePrompt}
                    </ExamplePrompt>
                  ))}
                </ExamplePromptList>
              </ExamplePrompts>
            </PromptBox>
          ) : (
            <GenerationTimeline
              key="generation-timeline"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <ImageContainer>
                <AnimatePresence mode="wait">
                  {generatedImage && (
                    <Image
                      key={currentVersion}
                      src={generatedImage}
                      alt={`Generated image ${currentVersion}`}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{ duration: 0.5 }}
                    />
                  )}
                </AnimatePresence>
              </ImageContainer>
              <TextContainer>
                <VersionText variants={textVariants}>
                  {currentVersion ? currentVersion.toUpperCase() : 'COMPLETE'}
                </VersionText>
                <StatusText variants={textVariants}>
                  {currentVersion ? (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5, repeat: Infinity, repeatType: 'reverse' }}
                    >
                      Generating image...
                    </motion.span>
                  ) : (
                    "Image generation complete!"
                  )}
                </StatusText>
                <PromptText variants={textVariants}>
                  Prompt: {prompt}
                </PromptText>
                <AnalysisText variants={textVariants}>
                  {analysis}
                </AnalysisText>
                <ButtonContainer>
                  {currentVersion && VERSIONS.indexOf(currentVersion) > 0 && (
                    <Button onClick={handlePrevious}>Previous</Button>
                  )}
                  {showNextButton && currentVersion !== VERSIONS[VERSIONS.length - 1] && (
                    <Button onClick={handleNext}>Next</Button>
                  )}
                </ButtonContainer>
              </TextContainer>
            </GenerationTimeline>
          )}
        </AnimatePresence>
      </ContentWrapper>
    </Container>
  );
};

export default ImageGenerationTimeline;