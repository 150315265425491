import React from 'react';
import { motion } from 'framer-motion';

const ExploreDigitalPathways = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        margin: '0',
        padding: '0',
        background: '#007BFF',
      }}
    >
      <motion.button
        onClick={() => window.open('https://www.canva.com/design/DAGRGqpkW0Y/9sNUBB9j4cbWxMY6QIFwgg/edit', '_blank')}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        style={{
          padding: '1rem 2rem',
          fontSize: '1.5rem',
          color: '#fff',
          backgroundColor: '#0056b3',
          border: 'none',
          borderRadius: '10px',
          cursor: 'pointer',
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
        }}
      >
        Presentation Example
      </motion.button>
    </div>
  );
};

export default ExploreDigitalPathways;
