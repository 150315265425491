import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const IntroSlide = ({ onIntroComplete }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleAnimationComplete = () => {
    if (!isVisible) {
      onIntroComplete();
    }
  };

  return (
    <AnimatePresence onExitComplete={handleAnimationComplete}>
      {isVisible && (
        <IntroContainer
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
      
          <IntroText>
            <PursuitText data-heading="PURSUIT">
              <GoldText>PUR</GoldText>
              <GreyText>SUIT</GreyText>
            </PursuitText>
            <PresentsText>PRESENTS</PresentsText>
          </IntroText>
        </IntroContainer>
      )}
    </AnimatePresence>
  );
};

const IntroContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(ellipse at center, #443501 0%, #000000 100%);
  z-index: 9999;
`;

const IntroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PursuitText = styled.h1`
  font-family: 'Playfair Display', serif;
  position: relative;
  text-transform: uppercase;
  font-size: calc(10vw + 0.5rem);
  margin: 0;
  font-weight: 400;

  &::after {
    background: none;
    content: attr(data-heading);
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    text-shadow: 
      -1px 0 1px #c6bb9f, 
      0 1px 1px #c6bb9f, 
      5px 5px 10px rgba(0, 0, 0, 0.4),
      -5px -5px 10px rgba(0, 0, 0, 0.4);
  }
`;

const GoldText = styled.span`
  color: #dbbf07;
`;

const GreyText = styled.span`
  color: #939293;
`;

const PresentsText = styled.h2`
  color: #939293;
  font-family: 'Playfair Display', serif;
  font-size: calc(5vw + 0.5rem);
  margin: 0;
  font-weight: 400;
`;

export default IntroSlide;
            