import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Book, ChevronRight } from 'lucide-react';

const TheBad = () => {
  const [index, setIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showUI, setShowUI] = useState(false);



  return (
    <Container>
   
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            style={{ width: '100%', maxWidth: '500px' }}
          >
            <GemsUI>
              <Logo>The Bad</Logo>
             
              <Divider />
           
              <LearningCoachCard>
               
                <CardContent>
                  <CardTitle>Discussion</CardTitle>
                  <CardDescription>
                 What are some of the not so great things about AI?
                  </CardDescription>
                </CardContent>
                <ChevronRight size="1.25rem" color="#666" />
              </LearningCoachCard>
            </GemsUI>
          </motion.div>
        
  
    </Container>
  );
};

export default TheBad;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  margin: 0;
  padding: 1rem;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
`;

const GemsUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #000;
  padding: 1.5rem;
  border-radius: 20px;
  width: 100%;
`;

const Logo = styled.h1`
  font-size: clamp(2.5rem, 10vw, 3.5rem);
  font-weight: 800;
  background: linear-gradient(45deg, #4169E1, #9400D3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  letter-spacing: -0.05em;
`;

const AssistantOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

const Circle = styled.div`
  width: clamp(2rem, 8vw, 2.25rem);
  height: clamp(2rem, 8vw, 2.25rem);
  border-radius: 50%;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: clamp(1rem, 4vw, 1.2rem);
  margin-right: 0.75rem;
`;

const OptionText = styled.span`
  color: white;
  font-size: clamp(1rem, 4vw, 1.2rem);
  flex-grow: 1;
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid #333;
  margin: 1.5rem 0;
`;

const GetStartedText = styled.h2`
  color: white;
  font-size: clamp(1.25rem, 5vw, 1.5rem);
  font-weight: 600;
  margin-bottom: 1.25rem;
`;

const LearningCoachCard = styled.div`
  display: flex;
  align-items: center;
  background-color: #111;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #1a1a1a;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(2.5rem, 10vw, 3rem);
  height: clamp(2.5rem, 10vw, 3rem);
  border-radius: 50%;
  background-color: rgba(65, 105, 225, 0.1);
  margin-right: 0.75rem;
  flex-shrink: 0;
`;

const CardContent = styled.div`
  flex-grow: 1;
  margin-right: 0.75rem;
`;

const CardTitle = styled.h3`
  color: white;
  font-size: clamp(1rem, 4vw, 1.2rem);
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

const CardDescription = styled.p`
  color: #888;
  font-size: clamp(0.75rem, 3vw, 0.9rem);
  line-height: 1.4;
`;