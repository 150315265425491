import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const VideoBackgroundSlide = ({ title, subtitle, videoSrc }) => {
  const [isMuted, setIsMuted] = useState(false); // Start unmuted
  const [isTextVisible, setIsTextVisible] = useState(true); // Text visibility state
  const videoRef = useRef(null);

  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  // UseEffect to fade out text after 15 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTextVisible(false);
    }, 15000); // 15 seconds

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  return (
    <SlideContainer>
      <VideoBackground ref={videoRef} autoPlay loop playsInline muted={isMuted}>
        <source src={videoSrc} type="video/mp4" />
      </VideoBackground>
      <ContentOverlay>
        <AnimatePresence>
          {isTextVisible && (
            <>
              <HologramTitle
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.8, ease: 'easeOut' }}
              >
                {title}
              </HologramTitle>
              <HologramSubtitle
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.8, delay: 0.2, ease: 'easeOut' }}
              >
                {subtitle}
              </HologramSubtitle>
            </>
          )}
        </AnimatePresence>
        <MuteButton onClick={toggleMute}>
          {isMuted ? '🔇' : '🔊'}
        </MuteButton>
      </ContentOverlay>
    </SlideContainer>
  );
};

const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const glowAnimation = keyframes`
  0% { text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff; }
  50% { text-shadow: 0 0 20px #00ffff, 0 0 30px #00ffff, 0 0 40px #00ffff; }
  100% { text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff; }
`;

const HologramTitle = styled(motion.h1)`
  font-size: 4rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 1rem;
  animation: ${glowAnimation} 3s ease-in-out infinite;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2.2rem;
  }
`;

const HologramSubtitle = styled(motion.h2)`
  font-size: 2rem;
  color: #00ffff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: 0.8;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const MuteButton = styled(motion.button)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
`;

export default VideoBackgroundSlide;
