// Presentation.js
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import ImageSlider from './ImageSlider';
import YouTubeEmbed from './YouTubeEmbed';
import WebEmbed from './WebEmbed';
import IntroSlide from './IntroSlide';

const colors = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A',
  '#98D8C8', '#F67280', '#C06C84', '#6C5B7B',
  '#355C7D', '#F8B195', '#F67280', '#C06C84'
];

const Presentation = ({ slides = [] }) => {
  const [showIntro, setShowIntro] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isControlPanelVisible, setIsControlPanelVisible] = useState(false);
  const [isTopControlPanelVisible, setIsTopControlPanelVisible] = useState(false);

  const sidebarRef = useRef(null);
  const controlPanelRef = useRef(null);
  const topControlPanelRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientX < 50 && e.clientY < window.innerHeight / 2) {
        setIsSidebarOpen(true);
      } else if (!sidebarRef.current?.contains(e.target)) {
        setIsSidebarOpen(false);
      }

      setIsTopControlPanelVisible(e.clientY < 50);
      setIsControlPanelVisible(e.clientY > window.innerHeight - 50);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const changeSlide = (direction) => {
    const newSlide = Math.max(0, Math.min(currentSlide + direction, slides.length - 1));
    setCurrentSlide(newSlide);
  };

  const jumpToSlide = (index) => {
    setCurrentSlide(index);
  };

  const groupedSlides = slides.reduce((acc, slide, index) => {
    if (!acc[slide.group]) {
      acc[slide.group] = [];
    }
    acc[slide.group].push({ ...slide, index });
    return acc;
  }, {});

  const getCurrentGroup = () => {
    return slides[currentSlide]?.group || '';
  };

  if (slides.length === 0) {
    return <EmptyState>Not Available Yet</EmptyState>;
  }

  const handleIntroComplete = () => {
    setShowIntro(false);
  };

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    }),
  };

  const renderSlideContent = (slide) => {
    if (!slide) return null;

    switch (slide.type) {
      case 'custom':
        return slide.component;
      case 'text':
        return <TextContent>{slide.content}</TextContent>;
      case 'image':
        return <ImageSlider images={slide.images} />;
      case 'image2':
        return <ImageSlider images={slide.images} />;
      case 'youtube':
        return (
          <YouTubeEmbed
            videoId={slide.videoId}
            youtubeState={{ state: 'paused', time: 0, volume: 100 }}
          />
        );
      case 'web':
        return <WebEmbed url={slide.url} />;
      default:
        return null;
    }
  };

  return (
    <FullscreenContainer>
      <AnimatePresence>
        {showIntro && <IntroSlide onIntroComplete={handleIntroComplete} />}
      </AnimatePresence>
      <AnimatePresence initial={false} custom={currentSlide}>
        <Slide
          key={currentSlide}
          custom={currentSlide}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          style={{ 
            backgroundColor: slides[currentSlide]?.type !== 'custom' 
              ? colors[currentSlide % colors.length]
              : 'transparent'
          }}
        >
          <SlideContent>
            {slides[currentSlide] && renderSlideContent(slides[currentSlide])}
          </SlideContent>
        </Slide>
      </AnimatePresence>

      <InteractionOverlay />

      <Sidebar
        ref={sidebarRef}
        initial={{ x: '-100%' }}
        animate={{ x: isSidebarOpen ? 0 : '-100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        {Object.entries(groupedSlides).map(([group, groupSlides]) => (
          <div key={group}>
            <GroupTitle>{group}</GroupTitle>
            {groupSlides.map((slide) => (
              <SidebarItem
                key={slide.index}
                onClick={() => jumpToSlide(slide.index)}
                $isActive={slide.index === currentSlide}
              >
                {slide.title}
              </SidebarItem>
            ))}
            <Divider />
          </div>
        ))}
      </Sidebar>

      <AnimatePresence>
        {isTopControlPanelVisible && (
          <TopControlPanel
            ref={topControlPanelRef}
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <GroupTitle>{getCurrentGroup()}</GroupTitle>
            <ControlButtonContainer>
              <ControlButton onClick={() => changeSlide(-1)} disabled={currentSlide === 0}>
                Previous
              </ControlButton>
              <ControlButton onClick={() => changeSlide(1)} disabled={currentSlide === slides.length - 1}>
                Next
              </ControlButton>
            </ControlButtonContainer>
          </TopControlPanel>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isControlPanelVisible && (
          <ControlPanel
            ref={controlPanelRef}
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <ControlButtonContainer>
              <ControlButton onClick={() => changeSlide(-1)} disabled={currentSlide === 0}>
                Previous
              </ControlButton>
              <ControlButton onClick={() => changeSlide(1)} disabled={currentSlide === slides.length - 1}>
                Next
              </ControlButton>
            </ControlButtonContainer>
          </ControlPanel>
        )}
      </AnimatePresence>
    </FullscreenContainer>
  );
};

const FullscreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const Slide = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SlideContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContent = styled.div`
  text-align: center;
  color: white;
  padding: 20px;
  max-width: 80%;
  font-size: 1.5rem;
  line-height: 1.6;
`;

const InteractionOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50%;
  z-index: 1001;
`;

const Sidebar = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
`;

const GroupTitle = styled.h3`
  color: white;
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SidebarItem = styled.div`
  color: white;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: ${props => props.$isActive ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 0;
`;

const ControlPanel = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const TopControlPanel = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ControlButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ControlButton = styled.button`
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  color: #333;
`;

export default Presentation;
