import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const WebEmbed = ({ url }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <EmbedContainer isLoaded={isLoaded}>
      <IframeWrapper isLoaded={isLoaded}>
        <iframe
          src={url}
          title="Embedded Content"
          allowFullScreen
          onLoad={() => setIsLoaded(true)}
          scrolling="no"
        />
      </IframeWrapper>
    </EmbedContainer>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const backgroundFadeIn = keyframes`
  from {
    background-color: #f0f0f0;
  }
  to {
    background-color: #ffffff;
  }
`;

const EmbedContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation: ${({ isLoaded }) => (isLoaded ? backgroundFadeIn : 'none')} 1s ease-in-out forwards;
`;

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? fadeIn : 'none')} 1s ease-in-out forwards;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block; /* Ensure the iframe is treated as a block element */
    object-fit: cover; /* Scale the iframe to cover the container */
    transition: opacity 1s ease-in-out;
    opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  }
`;

export default WebEmbed;