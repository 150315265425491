import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import * as fal from "@fal-ai/serverless-client";
import { FaDownload, FaExpand, FaCompress, FaQrcode, FaArrowLeft } from 'react-icons/fa';
import QRCode from 'qrcode.react';

fal.config({
  credentials: process.env.REACT_APP_FAL_KEY
});

const ImageGenerator = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    animal: '',
    color: '',
    clothing: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [error, setError] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageHistory, setImageHistory] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [qrCodeVisible, setQrCodeVisible] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setError(null);
  };

  const handleNext = () => {
    if (formData[Object.keys(formData)[step - 1]]) {
      setStep(step + 1);
    } else {
      setError('Please fill in the field before proceeding.');
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleGenerate = async () => {
    if (Object.values(formData).some(value => value === '')) {
      setError('Please fill in all fields.');
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const prompt = `A photorealistic full-body shot of a highly detailed ${formData.animal}, standing confidently, wearing a ${formData.color} cotton shirt with the name "${formData.name}" elegantly embroidered on it in a bold white script. The animal is also wearing ${formData.clothing}. The shirt's high-quality fabric features intricate stitching, with the texture and weave clearly visible. Set against a stunning New Zealand backdrop, with rolling green hills and snow-capped mountains in the distance, during the golden hour. The scene is bathed in soft backlighting, enhancing the richness of the colors. Captured in stunning 4K resolution with a Nikon Z7 II, 50mm f/1.4 lens, every detail of the fur, fabric, and the breathtaking landscape is sharply defined.`;
      setPrompt(prompt);

      const result = await fal.subscribe("fal-ai/flux-realism", {
        input: {
          prompt: prompt,
          image_size: "landscape_16_9",
          num_inference_steps: 50,
          guidance_scale: 3.5,
          num_images: 1,
          enable_safety_checker: true
        },
      });

      if (result.images && result.images.length > 0) {
        const imageUrl = result.images[0].url;
        setGeneratedImage(imageUrl);
        setImageHistory([...imageHistory, imageUrl]);
      } else {
        throw new Error('No image generated');
      }
    } catch (err) {
      setError('Failed to generate image. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setGeneratedImage(null);
    setFormData({ name: '', animal: '', color: '', clothing: '' });
    setStep(1);
    setImageLoaded(false);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <StepTitle>What's your name?</StepTitle>
            <InputField
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              onKeyPress={(e) => e.key === 'Enter' && handleNext()}
            />
          </>
        );
      case 2:
        return (
          <>
            <StepTitle>What's your favourite animal?</StepTitle>
            <InputField
              type="text"
              id="animal"
              value={formData.animal}
              onChange={handleChange}
              placeholder="Enter your favourite animal"
              onKeyPress={(e) => e.key === 'Enter' && handleNext()}
            />
          </>
        );
      case 3:
        return (
          <>
            <StepTitle>What's your favourite colour?</StepTitle>
            <InputField
              type="text"
              id="color"
              value={formData.color}
              onChange={handleChange}
              placeholder="Enter your favourite colour"
              onKeyPress={(e) => e.key === 'Enter' && handleNext()}
            />
          </>
        );
      case 4:
        return (
          <>
            <StepTitle>What is it wearing?</StepTitle>
            <InputField
              type="text"
              id="clothing"
              value={formData.clothing}
              onChange={handleChange}
              placeholder="e.g., black tophat, green diamond necklace"
              onKeyPress={(e) => e.key === 'Enter' && handleGenerate()}
            />
          </>
        );
      default:
        return null;
    }
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = generatedImage;
    link.download = 'generated_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const toggleQrCode = () => {
    setQrCodeVisible(!qrCodeVisible);
  };

  const togglePrompt = () => {
    setShowPrompt(!showPrompt);
  };

  const transitionProps = {
    initial: { opacity: 0, x: '100%' },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: '-100%' },
    transition: { type: 'spring', stiffness: 100, damping: 20 }
  };

  return (
    <Container style={{ height: `${windowHeight}px`, width: `${windowWidth}px` }}>
      <ContentWrapper>
        <AnimatePresence mode="wait">
          {!isLoading && !generatedImage ? (
            <InputSection
              key="input"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <Header>WELCOME</Header>
              {error && <Error>{error}</Error>}
              <Form>
                <motion.div {...transitionProps}>
                  {renderStep()}
                </motion.div>
                <ButtonGroup>
                  {step > 1 && <BackButton onClick={handleBack}><FaArrowLeft /> Back</BackButton>}
                  {step < 4 ? (
                    <NextButton onClick={handleNext}>Next</NextButton>
                  ) : (
                    <GenerateButton onClick={handleGenerate}>Generate Image</GenerateButton>
                  )}
                </ButtonGroup>
              </Form>
            </InputSection>
          ) : isLoading ? (
            <LoadingSection
              key="loading"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.5 }}
            >
              <Spinner />
            </LoadingSection>
          ) : (
            <ResultSection
              key="result"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <WelcomeText
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                Welcome to AI, {formData.name}!
              </WelcomeText>
              <ImageWrapper>
                <GeneratedImage
                  src={generatedImage}
                  alt="Generated Image"
                  onLoad={() => setImageLoaded(true)}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={imageLoaded ? { opacity: 1, scale: 1 } : {}}
                  transition={{ duration: 0.5 }}
                />
                {!imageLoaded && <ImagePlaceholder />}
              </ImageWrapper>
              <ImageControls>
                <ControlButton onClick={downloadImage}>
                  <FaDownload />
                </ControlButton>
                <ControlButton onClick={toggleQrCode}>
                  <FaQrcode />
                </ControlButton>
                <ControlButton onClick={toggleFullscreen}>
                  {isFullscreen ? <FaCompress /> : <FaExpand />}
                </ControlButton>
                <ControlButton onClick={togglePrompt}>
                  View Prompt
                </ControlButton>
              </ImageControls>
              <AnimatePresence>
                {qrCodeVisible && (
                  <QrCodeWrapper
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                  >
                    <QRCode value={generatedImage} />
                  </QrCodeWrapper>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {showPrompt && (
                  <PromptWrapper
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <PromptText>{prompt}</PromptText>
                  </PromptWrapper>
                )}
              </AnimatePresence>
              <ImageHistory>
                {imageHistory.map((image, index) => (
                  <ImageThumbnail
                    key={index}
                    src={image}
                    alt={`Generated ${index}`}
                    onClick={() => setGeneratedImage(image)}
                  />
                ))}
              </ImageHistory>
            </ResultSection>
          )}
        </AnimatePresence>
      </ContentWrapper>
      {generatedImage && (
        <FixedButton
          onClick={resetForm}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Generate Another Image
        </FixedButton>
      )}
      <AnimatePresence>
        {isFullscreen && (
          <FullscreenOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            onClick={toggleFullscreen}
          >
            <FullscreenImage src={generatedImage} alt="Fullscreen Generated Image" />
          </FullscreenOverlay>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #3498db, #8e44ad);
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

const Header = styled.h1`
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Error = styled(motion.div)`
  color: #e74c3c;
  margin-bottom: 1rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: clamp(0.8rem, 2vw, 1rem);
  animation: shake 0.5s;
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(10px); }
    50% { transform: translateX(-10px); }
    75% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }
`;

const InputSection = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StepTitle = styled.h2`
  font-size: clamp(1rem, 3vw, 1.5rem);
  color: white;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const InputField = styled(motion.input)`
  width: 100%;
  padding: 0.75rem;
  font-size: clamp(0.8rem, 2vw, 1rem);
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-weight: bold;
  &:focus {
    outline: none;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const Button = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  font-size: clamp(0.8rem, 2vw, 1rem);
  color: #3498db;
  background-color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  &:hover {
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
`;

const BackButton = styled(Button)`
  background-color: white;
  color: #3498db;
  &:hover {
    background-color: #f8f9fa;
  }
`;

const NextButton = styled(Button)``;

const GenerateButton = styled(Button)`
  width: 100%;
`;

const LoadingSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Spinner = styled(motion.div)`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ResultSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const WelcomeText = styled(motion.h2)`
  font-size: clamp(1.2rem, 4vw, 2rem);
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
`;

const ImageWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: 500px;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

const GeneratedImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImagePlaceholder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  
  &::after {
    content: 'Loading...';
    color: white;
    font-size: clamp(1rem, 3vw, 1.5rem);
  }
`;

const ImageControls = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const ControlButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  box-shadow: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const QrCodeWrapper = styled(motion.div)`
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PromptWrapper = styled(motion.div)`
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: clamp(0.8rem, 2vw, 1rem);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PromptText = styled.p`
  font-size: clamp(0.8rem, 2vw, 1rem);
  color: white;
  margin: 0;
`;

const ImageHistory = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  overflow-x: auto;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;

const ImageThumbnail = styled.img`
  width: 100px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const FixedButton = styled(Button)`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
`;

const FullscreenOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FullscreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

export default ImageGenerator;